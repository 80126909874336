<template>
  <b-overlay
    :show="isLoading"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body v-if="wheel">

        <b-form @submit.prevent="submit">
          <div class="mb-4 d-flex justify-content-between">
            <div>
              <p class="text-danger">
                ปิด - เปิดกงล้อ
              </p>
              <div>
                <b-form-checkbox
                  v-model="wheel.statusKR"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </div>
            </div>
            <div>
              <b-button
                variant="success"
                type="submit"
              >
                บันทึกข้อมูล
              </b-button>
            </div>
          </div>
          <div
            v-for="(bonus, idx) in wheel.bonusArrKR"
            class="row"
          >
            <div class="col-3">
              <b-form-group :label="`โบนัส ${idx + 1}:`">
                <b-form-input
                  v-model="bonus.text"
                  type="text"
                  required
                />
              </b-form-group>
            </div>
            <div class="col-3">
              <b-form-group label="ประเภท:">
                <b-form-select
                  v-model="bonus.type"
                  :options="bonusTypes"
                  type="text"
                  required
                />
              </b-form-group>
            </div>
            <div class="col-3">
              <b-form-group label="มูลค่า:">
                <b-form-input
                  v-model="bonus.value"
                  type="text"
                />
              </b-form-group>
            </div>
            <div class="col-3">
              <b-form-group label="เปอร์เซนต์ได้:">
                <b-form-input
                  v-model="bonus.percentage"
                  type="text"
                />
              </b-form-group>
            </div>
          </div>
        </b-form>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BForm, BButton, BModal, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BCardBody, VBToggle, BOverlay, BIconController, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BForm, BButton, BModal, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BCardBody, BOverlay, BIconController, BFormCheckbox,
  },
  data: () => ({
    wheel: null,
    isLoading: false,
    bonusTypes: [
      { text: 'ไม่มี', value: 'none' },
      { text: 'หมุนฟรี', value: 'free' },
      { text: 'พอยท์ (Shard, ชิ้นส่วนเพชร, อื่นๆ)', value: 'point' },
      { text: 'ชิป (เพชร, ชิป, อื่นๆ)', value: 'chip' },
      { text: 'เงิน', value: 'money' },
      { text: 'ทอง', value: 'gold' },
      { text: 'ล็อตเตอรี่', value: 'lotto' },
    ],
  }),
  mounted() {
    this.getWheel()
  },
  methods: {
    async submit(e) {
      this.isLoading = true
      try {
        const { data } = await this.$http.post('/wheel/update', this.wheel)

        await this.getWheel
        await this.$swal({
          icon: 'success',
          title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
          text: 'บันทึกข้อมูลเรียบร้อย',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async getWheel() {
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/wheel/index')

        this.wheel = data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
